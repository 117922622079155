import { html, render } from "lit-html"
import { ref } from "../directives"
import { virtual, useRef, useState } from "haunted"

import HXModal from "../generic/hx-modal"
import { renderRadioSelectOtherSpecify } from "../inputs"

const MODAL_TITLE_ID = "event-reg-modal-title"

const EventRegButtonForm = virtual(({ className = ``, ...props }) => {
  const buttonRef = useRef(null)
  const [open, setOpen] = useState(false)

  const onClose = () => {
    setOpen(false)

    if (buttonRef.current) {
      buttonRef.current.focus()
    }
  }

  return html`<button
      type="button"
      class="button ${className}"
      ?ref=${ref(buttonRef)}
      @click=${() => setOpen(!open)}
    >
      ${props.title}
    </button>
    ${open
      ? HXModal({
          ...props,
          name: "event-reg",
          labelledBy: MODAL_TITLE_ID,
          onClose,
          onAfterReq: () => {
            document
              .querySelectorAll(".single-other-specify")
              .forEach(renderRadioSelectOtherSpecify)
          },
        })
      : ``}`
})

export default EventRegButtonForm

export function renderEventRegButtonForm(el) {
  const {
    event_reg_form: { title, action },
  } = JSON.parse(
    document.querySelector(`[type="application/json"][data-props]`).innerText
  )

  render(
    EventRegButtonForm({
      title,
      action,
      className: "button-submit",
    }),
    el
  )
}
